export const COMMENTS = [
    {
        id: 0,
        campsiteId: 0,
        rating: 5,
        text: 'The grass was indeed greener here than our last campsite.',
        author: 'Simon Hunt',
        date: '2018-10-25T16:30Z'
    },
    {
        id: 1,
        campsiteId: 0,
        rating: 3,
        text: 'This lone monkey kept following us back to our tent.',
        author: 'Zandra Katherine',
        date: '2017-06-17T03:33Z'
    },
    {
        id: 2,
        campsiteId: 0,
        rating: 4,
        text: 'Do not feed the wildlife. They will follow you back to the tent.',
        author: 'Amy Keophilavong',
        date: '2019-02-18T18:12Z'
    },
    {
        id: 3,
        campsiteId: 0,
        rating: 3,
        text: "Avoid Sundays; couldn't relax and play my flute with all the noisy kids.",
        author: 'Jean Luc',
        date: '2019-08-04T20:11Z'
    },
    {
        id: 4,
        campsiteId: 0,
        rating: 5,
        text: "This has been our favorite campsite for over 7 years. It is an absolutely beautiful site. The hosts are very friendly and helpful. Don't forget to try out the lake.",
        author: 'R. E. Osariemen',
        date: '2018-07-23T19:44Z'
    },
    {
        id: 5,
        campsiteId: 1,
        rating: 4,
        text: "There's nothing more fun than setting up a tent in a rainstorm.",
        author: 'Juan Cortez',
        date: '2017-06-17T03:33Z'
    },
    {
        id: 6,
        campsiteId: 1,
        rating: 5,
        text: 'I caught a world-record-breaking trout here on my last visit.',
        author: 'McKenzie Sebastian',
        date: '2019-02-18T18:12Z'
    },
    {
        id: 7,
        campsiteId: 1,
        rating: 5,
        text: 'The campground was beautiful, but the bugs could bite sometimes.',
        author: 'Brennen Ech',
        date: '2019-08-04T20:11Z'
    },
    {
        id: 8,
        campsiteId: 1,
        rating: 4,
        text: "Love the river but I didn't catch any fish.",
        author: 'Amy Keophilavong',
        date: '2018-07-23T19:44Z'
    },
    {
        id: 9,
        campsiteId: 1,
        rating: 5,
        text: 'A wonderful place to reconnect with nature.',
        author: 'Jordan Runn',
        date: '2019-08-04T20:11Z'
    },
    {
        id: 10,
        campsiteId: 2,
        rating: 5,
        text: 'The Frozen Tundra is great place for family and friends camping!',
        author: 'Olaf the Snowman',
        date: '2019-02-18T18:12Z'
    },
    {
        id: 11,
        campsiteId: 2,
        rating: 1,
        text: 'We had to leave about 2am to go back to the city because the quiet was just too much!',
        author: 'Vincent and Mona Lisa Gambini',
        date: '2018-10-25T16:30Z'
    },
    {
        id: 12,
        campsiteId: 2,
        rating: 5,
        text: 'The stars at night were a revelation!',
        author: 'Ann Dabramov',
        date: '2018-07-23T19:44Z'
    },
    {
        id: 13,
        campsiteId: 2,
        rating: 4,
        text: 'Great scenic hiking trails but it was challenging to go up the mountain.',
        author: 'Amy Keophilavong',
        date: '2018-10-25T16:30Z'
    },
    {
        id: 14,
        campsiteId: 2,
        rating: 5,
        text: 'Perfect scenery for a smoked kipper breakfast with friends!',
        author: 'Dave Lister',
        date: '2019-08-04T20:11Z'
    },
    {
        id: 15,
        campsiteId: 3,
        rating: 4,
        text: 'Just remember a bear can run and climb faster than you...',
        author: 'Markell Richards',
        date: '2017-06-17T03:33Z'
    },
    {
        id: 16,
        campsiteId: 3,
        rating: 5,
        text: 'Excellent site to recharge from the city.',
        author: 'Amy Keophilavong',
        date: '2018-07-23T19:44Z'
    },
    {
        id: 17,
        campsiteId: 3,
        rating: 5,
        text: 'What a magnificent view!',
        author: 'Tinus Lorvaldes',
        date: '2018-10-25T16:30Z'
    },
    {
        id: 18,
        campsiteId: 3,
        rating: 4,
        text: 'The sites are huge! We felt like we were in the middle of nowhere, in a good way.',
        author: 'Chloe Haskins',
        date: '2017-06-17T03:33Z'
    },
    {
        id: 19,
        campsiteId: 3,
        rating: 5,
        text: "The photos don't do justice to the beauty of this place.",
        author: 'Minae Lee',
        date: '2019-02-18T18:12Z'
    }
];
